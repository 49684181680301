$(function() {
  function init() {
    $('[data-behaviour="toggle-menu-icon"]').on('click', toggleMenuIcon);
    $('[data-behaviour="toggle-link-icon"]').on('click', toggleSubMenu);
    $('[data-behaviour="toggle-link-cm"]').on('click', toggleSubMenucm);
  };
  
  function toggleMenuIcon() {
    $(this).toggleClass('menu-icon--open');
    $('[data-element="toggle-nav"]').toggleClass('nav--active');
  };
  
  function toggleSubMenu() {
    $(this).toggleClass('nav__link--plus nav__link--minus');
    $('[data-behaviour="toggle-sub-menu"]').slideToggle('nav__sub-list--active');
  };

  function toggleSubMenucm() {
    $(this).toggleClass('nav__link--plus nav__link--minus');
    $('[data-behaviour="toggle-sub-cm"]').slideToggle('nav__sub-list--active');
  };

  init()
});


// $(function() {
//   function init() {
//     $('[data-behaviour="toggle-menu-icon"]').on('click', toggleMenuIcon);
//     $('[data-behaviour="toggle-link-cm"]').on('click', toggleSubMenu);
//   };
  
//   function toggleMenuIcon() {
//     $(this).toggleClass('menu-icon--open');
//     $('[data-element="toggle-nav"]').toggleClass('nav--active');
//   };
  
  // function toggleSubMenu() {
  //   $(this).toggleClass('nav__link--plus nav__link--minus');
  //   $('[data-behaviour="toggle-sub-cm"]').slideToggle('nav__sub-list--active');
  // };
  
//   init()
// });






// плавный переход к якорю
$(document).ready(function(){
    $("#navclick").on("click","a", function (event) {
        event.preventDefault();
        var id  = $(this).attr('href'),
            top = $(id).offset().top;
        $('body,html').animate({scrollTop: top}, 2000);
    });
});